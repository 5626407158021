export default class DateService {

  /**
   * Adds days to date
   * @param date
   * @param days
   * @returns {Date}
   */
  static addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);

    return copy;
  }

  /**
   * Formats Date MM/DD/YYYY
   * @param date
   * @returns {string|null}
   */
  static formatDate (date) {
    if (!date) {
      return null;
    }

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }

  /**
   * Parses Date
   * @param date
   * @returns {string|null}
   */
  static parseDate (date) {
    if (!date) {
      return null;
    }

    const [month, day, year] = date.split('/');

    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
}
