<template>
    <div class="creative__container" v-if="tab !== null">
        <v-container>
            <v-tabs
                v-model="tab"
                @change="tabChanged($event)"
                fixed-tabs
                dark icons-and-text
            >
                <v-tab
                    v-for="t in tabs"
                    :key="t.key"
                    :disabled="t.disabled"
                >
                    {{ t.name }}
                    <v-icon>{{ t.icon }}</v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="t in tabs"
                    :key="t.key"
                    touchless
                >
                    <component
                        :is="t.component"
                        :id="id"
                        :data="data"
                    ></component>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'CreativeContainer',

        props: ['id', 'tabs', 'data'],

        data: () => ({
            tab: null
        }),

        beforeMount() {
            if (this.tab === null) {
                this.tab = this.tabs[0].key;
            }
            if (this.id !== null) {
                const newIndex = this.findTab(this.$route.params.selectedTab);
                const currentIndex = this.findTab(this.tab);

                if (currentIndex !== newIndex) {
                    this.tab = newIndex;
                }
            } else {
                this.tab = 0;
            }
        },

        methods: {
            /**
             * When selected tab changes, change route if it's not the same.
             * @param newTabIndex
             */
            tabChanged(newTabIndex) {
                const currentTab = this.$route.params.selectedTab;
                if (currentTab === this.tabs[newTabIndex].key) {
                    return;
                }

                this.updateRoute(newTabIndex);
            },

            /**
             * Search for the tab according to tab key.
             * @param tabKey
             * @returns {*}
             */
            findTab(tabKey) {

                return _.findIndex(this.tabs, t => {
                    return t.key === tabKey;
                });
            },

            /**
             * Update the current route with correct params.
             * @param newTabIndex
             */
            updateRoute(newTabIndex) {
                this.$router.replace({
                    name: this.$route.name,
                    params: {
                        id: this.id,
                        selectedTab: this.tabs[newTabIndex].key
                    }
                });
            }
        },

        watch: {
            '$route.params.selectedTab'(val) {
                if (_.isUndefined(val)) {
                    this.updateRoute(0);
                } else if (!_.isNull(this.id) && _.isUndefined(val)) {
                    this.tab = this.findTab(val);
                }
            }
        }
    }
</script>
